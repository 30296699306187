import React, { useState, useRef } from 'react'
import "./ContactSection.css";
import personIcon from "../../images/person.png"
import axios from "axios"


function ContactSectionCorporate(props) {
    const data = props.data;
    console.log(props.selectedService)

    const [formData, setFormData] = useState( props.selectedService ? { services: props.selectedService } : { services: "Ταμειακή Διαχείριση (Cash Flow)" });
    const [emailResponse, setEmailResponse] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        formData.language = "EL";
        console.log(formData);

        axios.post('https://admin.ficon.gr/wp-json/myplugin/v1/scheduleAction', formData)
            .then(response => {
                setEmailResponse(response.data);
            })
            .catch(error => {
                console.log(error)
                setEmailResponse("Παρουσιάστηκε πρόβλημα, δοκιμάστε να επικοινωνήσετε μαζί μας στο τηλέφωνο 696 xxx xxx x");
                // handle error
            });
    };

    return (
        <div id="schedule-form" className="py-16 md:py-32 m-auto text-center px-4 md:px-0 container-cs z-10">
            <div className='flex flex-wrap justify-center'>
                <div className='pr-0 md:pr-8 pb-4 w-full md:w-1/4'>
                    <img src={personIcon} width="155px" />
                </div>
                {!emailResponse && (
                    <div className='w-full md:w-3/4'>
                        <div className='w-full text-left'>
                            <h2>{data.title}</h2>
                            <div className='ss-sub pt-4 pb-8' dangerouslySetInnerHTML={{ __html: data.content }} />
                        </div>

                        <div className='w-full pt-12 md:pt-0 flex flex-wrap justify-center'>

                            <form onSubmit={handleSubmit} className='flex flex-wrap justify-center'>
                                {data.form && data.form.map((field, i) => {
                                    if (field.type == "textarea") {
                                        return (
                                            <div key={`form-field-${i}`} className="form-textareas">
                                                <textarea id={field.value} onChange={handleInputChange} name={field.value} className="form-control" placeholder={field.label} cols="30" rows="6" required />
                                            </div>
                                        )
                                    } else if (field.type == "select") {
                                        return (
                                            <div key={`form-field-${i}`} className="form-inputs">
                                                <select id={field.value} value={formData.services} onChange={handleInputChange} type={field.type} name={field.value} className="form-control" placeholder={field.label} required >
                                                    <option>Αξιολόγηση Χρηματοπιστωτικού Κινδύνου</option>
                                                    <option>MIS & Corporate Reporting</option>
                                                    <option>Fundraising/M&A</option>
                                                    <option>Restructuring Strategy & Business Plan</option>
                                                    <option>Corporate Governance</option>
                                                </select>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={`form-field-${i}`} className="form-inputs">
                                                <input id={field.value} onChange={handleInputChange} type={field.type} name={field.value} className="form-control" placeholder={field.label} required />
                                            </div>
                                        )
                                    }
                                })}
                            </form>

                            <div className='flex justify-start pt-6 md:pt-0 '>
                                <button onClick={handleSubmit} className='main-bttn'>
                                    <span>{data.formCta}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {emailResponse && (
                    <div className="email-responses" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                )}
            </div>
        </div>
    );
}

export default ContactSectionCorporate;
