import React, { useEffect, useState, useCallback } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import ContactSectionCorporate from "../components/Home/ContactSectionCorporate"

import { el } from 'date-fns/locale'

// import RelatedPosts from '../components/RelatedPosts'
import htmlDecode from "html-entities-decoder";
import { format } from 'date-fns'

import "../components/Blog/blog.css"
import '@wordpress/block-library/build-style/style.css'


function BlogPostTemplate(props) {
    let data = props.data;
    console.log(data)
    let availableVersionsFiltered = props.pageContext.availablePages

    if (availableVersionsFiltered[0]) {
        let tmp = `${availableVersionsFiltered[0].language.slug === "en" ? `/${availableVersionsFiltered[0].language.slug}` : ""}/blog/${availableVersionsFiltered[0].slug}`

        availableVersionsFiltered[0].uri = tmp;
    }

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://ficon.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://ficon.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            mode="dark"
            metaData={constructMetaData(data.allWpPost.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
            globalInfo={data.wpTemplate.globalInformation}
        >
            <article className="m-auto" style={{ maxWidth: "1170px" }}>
                <div className="container-cs mx-auto py-16 md:py-32 px-4">
                    <h1 className="service-h1">{data.allWpPost.nodes[0].title}</h1>
                    <div className="sp-img-container w-full relative m-auto pt-16 pb-8" style={{ maxWidth: "550px" }} >
                        {(data.allWpPost.nodes[0] && data.allWpPost.nodes[0].featuredImage && data.allWpPost.nodes[0].featuredImage.node) && (
                            // <GatsbyImage
                            //     image={data.allWpPost.nodes[0].featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                            //     alt={data.allWpPost.nodes[0].featuredImage.node.altText}
                            //     className=" w-full pb-6 sp-f-img" />
                            <img src={data.allWpPost.nodes[0].featuredImage.node.sourceUrl} alt={data.allWpPost.nodes[0].featuredImage.node.altText} />
                        )}
                    </div>
                    <div className="service-editor" dangerouslySetInnerHTML={{ __html: data.allWpPost.nodes[0].content }} />
                </div>
            </article>

            <section className="relative">
                <ContactSectionCorporate
                    data={data.wpTemplate.globalInformation?.contactForm}
                    selectedService={data.allWpPost.nodes[0].title}
                />
            </section>

        </Layout>
    );
}

export default BlogPostTemplate


export const pageQuery = graphql`query BLOG_POST_QUERY($id: String,  $templateId: String) {

    allWpPost(filter: {id:{eq: $id }} ) {
        nodes {
            slug
            title
            content
            date
            author {
                node {
                    name
                    firstName
                    avatar {
                        url
                    }
                }
            }
            seo {
                title
                metaDesc
                opengraphDescription
                opengraphTitle
                opengraphImage {
                sourceUrl
                }
            }
            featuredImage {
                node {
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                        }
                    }
                }
            }
        }
      }

      wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
